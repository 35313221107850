import React from 'react'
import clx from 'classnames'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Article, { ArticleData } from '../components/Article'
import SEO from '../components/Seo'
import styles from './archives.module.css'
import Pagination from '../components/Pagination'
import Banner from '../components/Banner'

const getTerm = data => {
  const pageInfo = data.allWpPost?.pageInfo || null
  if (pageInfo && pageInfo.currentPage > 1) {
    return data
  }

  if (data.wpPage) {
    return {
      ...data,
      wpTermNode: {
        originalTerm: data.wpTermNode.name,
        ...data.wpTermNode,
        ...data.wpPage,
        seo: {
          ...data.wpTermNode.seo,
          metaDesc: data.wpPage.seo.metaDesc || data.wpTermNode.seo.metaDesc,
          opengraphDescription:
            data.wpPage.seo.opengraphDescription ||
            data.wpTermNode.seo.opengraphDescription,
          title: data.wpPage.seo.title || data.wpTermNode.seo.title,
          opengraphTitle:
            data.wpPage.seo.opengraphTitle ||
            data.wpTermNode.seo.opengraphTitle,
        },
      },
    }
  } else return data
}

export default function Archive({ data, pageContext }) {
  const { basePath } = pageContext
  const term = getTerm(data).wpTermNode
  const posts = data.allWpPost?.nodes || []
  const pageInfo = data.allWpPost?.pageInfo || null
  const { currentPage } = pageInfo
  const isPaged = currentPage > 1

  // console.log(post)
  const canonical = `${term.seo.canonical || term.uri}${
    isPaged ? `page/${currentPage}/` : ''
  }`
  const title = `${term.seo.title}${isPaged ? ` - Page ${currentPage}` : ''}`

  return (
    <Layout>
      <SEO
        title={title}
        description={term.seo.metaDesc}
        canonical={canonical}
        schema={term.seo.schema.raw}
      />
      <div
        className={clx(
          styles.titleContainer,
          !!term.description && styles.withDescription
        )}
      >
        <h1 className={clx(styles.title, isPaged && styles.paged)}>
          {isPaged ? (
            <>
              {term.slug === 'news' ? (
                `Latest News - Page ${currentPage}`
              ) : (
                <>
                  Latest articles on "{term.originalTerm || term.name}" - Page{' '}
                  {currentPage}
                </>
              )}
            </>
          ) : (
            `${term.name}`
          )}
          {/* {isPaged && ` | Page ${currentPage}`} */}
        </h1>
        {!!(term.description && !isPaged) && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: term.description,
            }}
          />
        )}
      </div>
      <section>
        {!isPaged && (
          <h4 className={styles.sectionTitle}>
            {term.slug === 'news' ? (
              `Latest News`
            ) : (
              <>Latest articles on "{term.originalTerm || term.name}"</>
            )}
          </h4>
        )}
        {posts
          .reduce(
            (prev, curr, index) =>
              index === 5 ? [...prev, 'banner', curr] : [...prev, curr],
            []
          )
          .map(node =>
            node === 'banner' ? (
              <Banner
                className="adsbygoogle"
                style={{ display: 'block' }}
                slot="3996326775"
                format="auto"
                responsive="true"
              />
            ) : (
              <Article key={node.slug} data={node as ArticleData} />
            )
          )}

        {pageInfo && (
          <Pagination
            basePath={basePath}
            pageInfo={pageInfo}
            baseTitle={term.seo.title}
          />
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: Int!, $slug: String!, $limit: Int!, $skip: Int!) {
    allWpPost(
      filter: { terms: { nodes: { elemMatch: { absoluteId: { in: [$id] } } } } }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        perPage
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
      nodes {
        title
        excerpt
        slug
        uri
        date(formatString: "DD MMM, yyyy")
        dateGmt
        categories {
          nodes {
            uri
            name
          }
        }
        featuredImage {
          node {
            seo {
              metaDesc
            }
            mobileImage: localFile {
              childImageSharp {
                fluid(
                  maxWidth: 120
                  maxHeight: 120
                  cropFocus: CENTER
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            desktopImage: localFile {
              childImageSharp {
                fluid(
                  maxHeight: 200
                  maxWidth: 200
                  cropFocus: CENTER
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: $slug }) {
      name: title
      description: content
      seo {
        title
        metaDesc
        canonical
        schema {
          raw
        }
      }
    }
    wpTermNode(databaseId: { eq: $id }) {
      name
      uri
      description
      slug
      ... on WpCategory {
        id
        seo {
          title
          metaDesc
          canonical
          schema {
            raw
          }
        }
      }
      ... on WpTag {
        id
        seo {
          title
          metaDesc
          canonical
          schema {
            raw
          }
        }
      }
    }
  }
`
